'use strict';

jQuery(document).ready(function ($) {

  $('.navMain').dropit();

  //$( '.swipebox' ).swipebox();

  $('.mh-item').matchHeight();
  $('.infoBox__list').matchHeight();
  $('.main__item').matchHeight();
});