'use strict';

/* js/navMain.js */

jQuery(document).ready(function ($) {

    $('.navMain').before('<i class="js_navMain_trigger navMain_trigger fa fa-bars" aria-hidden="true"></i>');

    /*$('.navMain').prepend('<i class="js_navMain_trigger navMain_trigger fa fa-close" aria-hidden="true"></i>');*/

    $('body').on('click', '.js_navMain_trigger', function () {
        $('body').toggleClass('navMain-open');
    });
});